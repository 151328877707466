import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  IFinanceApi,
  SalesInvoiceHeaderDto,
  SalesOrderHeaderV3Dto,
  SalesInvoiceLineDto,
  VendorSearchDto,
  LegalEntityLookupDto,
  D365UserImpersonationDto,
  D365UserDto,
  CustomerSearchDto,
  InvoiceDataDto,
  PurchaseOrderHeaderV2Dto,
  POSearchParametersDto,
  PODataResultDto,
  VendTransBiEntityDto,
  CustTransBiEntityDto,
  VendTransSearchParametersDto,
  CustTransSearchParametersDto,
} from '@psp/pogona_vendor_api_lib';
import { ClientAuthenticatedAdalApiClient } from '@psp/pogona_adal_js';
import { VendorPortalFinanceInitArgs } from '@/utils/VendorPortalFinanceInitArgs';
import {
  financeApiClient,
  callGetSalesInvoiceHeaders,
  callGetSalesInvoice,
  callSearchInvoices,
  callSearchVendors,
  getLegalEntities,
  callImpersonateD365User,
  callGetD365UserImpersonation,
  callStopImpersonatingD365User,
  callGetUserData,
  callSearchCustomers,
  callRemoveUserAccount,
  callDeleteUserCacheEntry,
  callSearchPurchaseOrderHeaders,
  callGetPOByNumber,
  callSearchVendorsWithRestriction,
  callSearchVendTransactions,
  callSearchCustTransactions,
  callSearchCustomersWithRestriction,
  callGetCustBalance,
  callGetVendBalance,
  callGetVendSettlementsForTrans,
  callGetCustSettlementsForTrans,
  VendSettlementResultJS,
  CustSettlementResultJS,
  callGetPODCDocuments,
} from '@/utils/FinanceApiClient.fs.js';
import store from '@/store';
import FileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { PODCDocumentDataDto } from '@/fable_modules/pogona_vendor_api_lib.fable.1.473.0/D365Types.fs';

function constructBaseUrl(initArgs: VendorPortalFinanceInitArgs): string {
  return initArgs.OverrideUrl.length > 0 ? initArgs.OverrideUrl : initArgs.Origin;
}

export async function callFinanceApi(
  getClient: (() => Promise<IFinanceApi>) | null,
  api: (client: IFinanceApi) => Promise<any>,
): Promise<any> {
  const client_1 = await getFinanceClientOrThrow(getClient);
  return await api(client_1);
}

// Create function to get finance client if not null otherwise throw error
export function getFinanceClientOrThrow(
  getClient: (() => Promise<IFinanceApi>) | null,
): Promise<IFinanceApi> {
  if (getClient) {
    return getClient();
  } else {
    throw new Error('getClient function not set');
  }
}

// Define the store
export const useVpFinanceStore = defineStore('vpFinanceStore', () => {
  const test = ref('test');
  const salesInvoiceHeaders = ref<SalesInvoiceHeaderDto[]>([]);
  const salesInvoiceHeader = ref<SalesInvoiceHeaderDto | null>(null);
  const salesOrderHeader = ref<SalesOrderHeaderV3Dto | null>(null);
  const salesInvoiceLines = ref<SalesInvoiceLineDto[]>([]);
  const salesInvoiceSearchResults = ref<SalesInvoiceHeaderDto[]>([]);
  const vendorSearchResult = ref<VendorSearchDto[]>([]);
  const customerSearchResult = ref<CustomerSearchDto[]>([]);
  const legalEntityLookup = ref<LegalEntityLookupDto[]>([]);
  const authClient = ref<ClientAuthenticatedAdalApiClient | null>(null);
  const getFinanceClient = ref<(() => Promise<IFinanceApi>) | null>(null);
  const invoiceData = ref<InvoiceDataDto | null>(null);
  const poSearchResults = ref<PurchaseOrderHeaderV2Dto[]>([]);
  const po = ref<PODataResultDto | null>(null);
  const vendorSearchResultWithRestriction = ref<VendorSearchDto[]>([]);
  const vendTransResult = ref<VendTransBiEntityDto[]>([]);
  const custTransResult = ref<CustTransBiEntityDto[]>([]);
  const custSearchResultWithRestriction = ref<CustomerSearchDto[]>([]);
  const vendSettlementResult = ref<VendSettlementResultJS>(emptyVendSettlementResult());
  const custSettlementResult = ref<CustSettlementResultJS>(emptyCustSettlementResult());
  const poDCDocuments = ref<PODCDocumentDataDto[]>([]);

  function init(args: VendorPortalFinanceInitArgs) {
    authClient.value = args.AuthClient;
    const getToken = async (client: ClientAuthenticatedAdalApiClient) => {
      return client.client.getToken() as Promise<string>;
    };
    const baseUrl = constructBaseUrl(args);
    getFinanceClient.value = async () => {
      return financeApiClient(baseUrl, () => getToken(authClient.value));
    };
  }

  function setTest(value: string) {
    test.value = value;
  }

  async function getLegalEntityLookup() {
    try {
      if (legalEntityLookup.value.length === 0) {
        const client = await getFinanceClientOrThrow(getFinanceClient.value);
        legalEntityLookup.value = (await getLegalEntities(client)).sort(
          (a: LegalEntityLookupDto, b: LegalEntityLookupDto) =>
            a.LegalEntityId.localeCompare(b.LegalEntityId),
        );
      }
      return legalEntityLookup.value;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error retrieving legal entity lookup ${JSON.stringify(error)}`,
        id: '6cdb5876-3806-4be6-82f3-a45103b6cfb6',
      });
      throw error;
    }
  }

  async function searchVendors(
    search: string,
    dataAreaIds: string[],
    skip: number,
    take: number,
  ): Promise<void> {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const result = await callSearchVendors(search, dataAreaIds, skip, take, client);
      vendorSearchResult.value = result;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching vendors ${JSON.stringify(error)}`,
        id: 'b9b6c3ac-b878-4f23-a74d-a85c9588ce35',
      });
    }
  }

  async function searchCustomers(
    search: string,
    dataAreaIds: string[],
    skip: number,
    take: number,
  ): Promise<void> {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const result = await callSearchCustomers(search, dataAreaIds, skip, take, client);
      customerSearchResult.value = result;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching customers ${JSON.stringify(error)}`,
        id: '7576aec7-98a9-4366-bbb1-0e5c5d46f814',
      });
    }
  }

  async function getSalesInvoiceHeaders(skip: number, take: number) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const result = await callGetSalesInvoiceHeaders(skip, take, client);
      salesInvoiceHeaders.value = result;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error retrieving invoice headers ${JSON.stringify(error)}`,
        id: 'c965814b-e0ec-43b3-b7ff-5223861845d3',
      });
    }
  }

  async function getSalesInvoice(invoiceNumber: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const result = await callGetSalesInvoice(invoiceNumber, client);
      if (result) {
        salesInvoiceHeader.value = result[0];
        salesOrderHeader.value = result[1];
        salesInvoiceLines.value = result[2];
        invoiceData.value = result[3];
      } else {
        salesInvoiceHeader.value = null;
        salesInvoiceLines.value = [];
      }
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error retrieving invoice ${JSON.stringify(error)}`,
        id: '239c0541-a477-4d60-abdf-cd86efaa47ec',
      });
    }
  }

  async function getInvoiceExcel(invoiceId: string, protect: boolean) {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/ifinanceapi/getSalesInvoiceExcel/${invoiceId}/${protect}`,
        { responseType: 'blob' },
      );
      FileSaver.saveAs(response.data, `Invoice-${invoiceId}-${getDateStringForExcel()}.xlsx`);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error retrieving invoice excel ${JSON.stringify(error)}`,
        id: 'f695f269-8a84-4c8b-974a-e2a9134f90ab',
      });
    }
  }

  async function searchInvoices(
    search: string,
    fromDate: string | null,
    toDate: string | null,
    skip: number,
    take: number,
  ) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const result = await callSearchInvoices(
        {
          Search: search,
          FromDate: fromDate ? new Date(fromDate) : null,
          ToDate: toDate ? new Date(toDate) : null,
          Skip: skip,
          Take: take,
        },
        client,
      );
      salesInvoiceSearchResults.value = result;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching invoices ${JSON.stringify(error)}`,
        id: '58922e2e-e82c-44f1-8ce5-d559d592f2c2',
      });
    }
  }

  async function impersonateD365User(userId: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      await callImpersonateD365User(userId, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error impersonating D365 user ${JSON.stringify(error)}`,
        id: '7be9013f-c38d-452d-8a5e-403706446719',
      });
    }
  }

  async function getD365UserImpersonation(): Promise<D365UserImpersonationDto> {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const user = await callGetD365UserImpersonation(client);
      return user;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting D365 user impersonation ${JSON.stringify(error)}`,
        id: 'c65a504d-b600-490f-922d-7feb4ce30142',
      });
      throw error;
    }
  }

  async function stopImpersonatingD365User() {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      await callStopImpersonatingD365User(client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error stopping impersonating D365 user ${JSON.stringify(error)}`,
        id: '939e1c50-13e9-4d5b-9b87-f0c65e868bb2',
      });
    }
  }

  async function isPspFinance() {
    return await authClient.value?.client.roleHasRights('PSPFinance');
  }

  async function getUserData(userId: string): Promise<D365UserDto | null> {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      return await callGetUserData(userId, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting user data ${JSON.stringify(error)}`,
        id: 'a7baf992-1dee-40d4-9223-a0cf77ce3f20',
      });
      throw error;
    }
  }

  async function removeAccount(userName: string, account: string, accountType: number) {
    const client = await getFinanceClientOrThrow(getFinanceClient.value);
    return await callRemoveUserAccount(userName, account, accountType, client);
  }

  async function removeCustomerAccount(userName: string, account: string) {
    try {
      return await removeAccount(userName, account, 2);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error removing customer account ${JSON.stringify(error)}`,
        id: '67d2cbe4-14bd-40c9-bf97-3ed2c064397b',
      });
      throw error;
    }
  }

  async function removeVendorAccount(userName: string, account: string) {
    try {
      return await removeAccount(userName, account, 1);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error removing vendor account ${JSON.stringify(error)}`,
        id: '8a122a47-48a1-41dc-aa58-8aeef82d0dce',
      });
      throw error;
    }
  }

  async function addAccount(userName: string, account: string, accountType: number) {
    const createdBy = await store.dispatch('app/getUserName');
    return await authClient.value?.client.http.post('/vendorapi/useraccount', {
      CreatedAt: new Date(),
      CreatedBy: createdBy,
      UserAccountId: uuid(),
      UserName: userName,
      AccountNumber: account,
      UserAccountTypeId: accountType,
    });
  }

  async function addCustomerAccount(userName: string, account: string) {
    try {
      return await addAccount(userName, account, 2);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error adding customer account ${JSON.stringify(error)}`,
        id: '2fb68f23-a6e8-4c36-9e6d-ec71c181039d',
      });
      throw error;
    }
  }

  async function addVendorAccount(userName: string, account: string) {
    try {
      return await addAccount(userName, account, 1);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error adding vendor account ${JSON.stringify(error)}`,
        id: '54034d78-1eb9-472a-8abe-ef37cc3305fb',
      });
      throw error;
    }
  }

  async function deleteUserCacheEntry(userName: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      return await callDeleteUserCacheEntry(userName, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error deleting user cache entry ${JSON.stringify(error)}`,
        id: '8317f2bd-7309-40e4-91d5-cef1b53bc8cf',
      });
      throw error;
    }
  }

  async function searchPurchaseOrderHeaders(parameters: POSearchParametersDto) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      poSearchResults.value = await callSearchPurchaseOrderHeaders(parameters, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching purchase order headers ${JSON.stringify(error)}`,
        id: '0a1495de-d9fe-469d-b4a0-60794017e244',
      });
    }
  }

  async function getPOByNumber(poNumber: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      po.value = await callGetPOByNumber(poNumber, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error retrieving purchase order ${JSON.stringify(error)}`,
        id: '78ca809f-da44-4673-8c7e-f1b9750262f6',
      });
    }
  }

  async function getPOExcel(poNumber: string, protect: boolean) {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/ifinanceapi/getpoexcel/${poNumber}/${protect}`,
        { responseType: 'blob' },
      );
      // Get time string in format yyyyMMddHHmmss
      const now = new Date();
      const dateString = now.toISOString().slice(0, 19).replace(/[-:]/g, '').replace('T', '');
      FileSaver.saveAs(response.data, `PO-${poNumber}-${dateString}.xlsx`);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error retrieving purchase order excel ${JSON.stringify(error)}`,
        id: 'a9562812-5c86-498e-a570-aa048f675384',
      });
    }
  }

  async function searchVendorsWithRestriction(search: string, skip: number, take: number) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const result = await callSearchVendorsWithRestriction(search, skip, take, client);
      vendorSearchResultWithRestriction.value = result;
      return result;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching vendors with restriction ${JSON.stringify(error)}`,
        id: '41b3230e-0c08-4192-ad9f-eea6fb5212f6',
      });
    }
  }

  async function searchVendTransactions(parameters: VendTransSearchParametersDto) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      vendTransResult.value = await callSearchVendTransactions(parameters, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching vendor transactions ${JSON.stringify(error)}`,
        id: '3519b99b-92cc-444a-8ded-b201d777c80b',
      });
    }
  }

  async function searchCustTransactions(parameters: CustTransSearchParametersDto) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      custTransResult.value = await callSearchCustTransactions(parameters, client);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching customer transactions ${JSON.stringify(error)}`,
        id: '73bd7f74-a146-4354-a75d-8a32122585f1',
      });
    }
  }

  function addVendorSearchResult(vendor: VendorSearchDto) {
    vendorSearchResultWithRestriction.value.push(vendor);
  }

  async function searchCustomersWithRestriction(search: string, skip: number, take: number) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const result = await callSearchCustomersWithRestriction(search, skip, take, client);
      custSearchResultWithRestriction.value = result;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error searching customers with restriction ${JSON.stringify(error)}`,
        id: 'a2aace11-4716-4510-bc7e-54fddefa8beb',
      });
    }
  }

  function addCustomerSearchResult(customer: CustomerSearchDto) {
    custSearchResultWithRestriction.value.push(customer);
  }

  async function getCustBalance(accountNumber: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      custTransResult.value = await callGetCustBalance(accountNumber, client);
      return custTransResult.value;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting customer balance ${JSON.stringify(error)}`,
        id: '1e1a7d5c-336f-4593-950d-3298fff6b2ca',
      });
      throw error;
    }
  }

  async function getVendBalance(accountNumber: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      vendTransResult.value = await callGetVendBalance(accountNumber, client);
      return vendTransResult.value;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting vendor balance ${JSON.stringify(error)}`,
        id: '0abd6155-4d08-46a4-9f6f-7e1b8a5dfe8d',
      });
      throw error;
    }
  }

  async function getVendSettlementsForTrans(voucher: string, dataAreaId: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      vendSettlementResult.value = await callGetVendSettlementsForTrans(
        voucher,
        dataAreaId,
        client,
      );
      return vendSettlementResult.value;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting vendor settlements ${JSON.stringify(error)}`,
        id: '36c71869-d343-44e7-9c57-206da55ab7dc',
      });
      throw error;
    }
  }

  async function getCustSettlementsForTrans(voucher: string, dataAreaId: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      custSettlementResult.value = await callGetCustSettlementsForTrans(
        voucher,
        dataAreaId,
        client,
      );
      return custSettlementResult.value;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting customer settlements ${JSON.stringify(error)}`,
        id: '27b91929-b1e9-40ec-8696-c3c0909a6e0c',
      });
      throw error;
    }
  }

  function emptyVendSettlementResult() {
    return new VendSettlementResultJS([], []);
  }

  function emptyCustSettlementResult() {
    return new CustSettlementResultJS([], []);
  }

  function getDateStringForExcel() {
    const now = new Date();
    return now.toISOString().slice(0, 19).replace(/[-:]/g, '').replace('T', '');
  }

  async function exportCustTransToExcel(voucher: string, dataAreaId: string, protect: boolean) {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/ifinanceapi/getcusttranssettlementsexcel/${voucher}/${dataAreaId}/${protect}`,
        { responseType: 'blob' },
      );
      FileSaver.saveAs(
        response.data,
        `CustTransSettlements-${voucher}-${getDateStringForExcel()}.xlsx`,
      );
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting customer settlements excel ${JSON.stringify(error)}`,
        id: '7885a25a-bb0c-4e26-b814-384bb608eeaf',
      });
      throw error;
    }
  }

  async function exportVendTransToExcel(voucher: string, dataAreaId: string, protect: boolean) {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/ifinanceapi/getvendtranssettlementsexcel/${voucher}/${dataAreaId}/${protect}`,
        { responseType: 'blob' },
      );
      FileSaver.saveAs(
        response.data,
        `VendTransSettlements-${voucher}-${getDateStringForExcel()}.xlsx`,
      );
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting vendor settlements excel ${JSON.stringify(error)}`,
        id: '2ddec750-ba7c-49c5-b2ca-d9663892622a',
      });
      throw error;
    }
  }

  async function exportCustTransSearchToExcel(
    search: CustTransSearchParametersDto,
    protect: boolean,
  ) {
    try {
      const response = await authClient.value?.client.http.post(
        `/vendorapi/ifinanceapi/getcusttransexcel/${protect}`,
        JSON.stringify(search),
        { responseType: 'blob' },
      );
      FileSaver.saveAs(response.data, `CustTrans-${getDateStringForExcel()}.xlsx`);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting customer transactions excel ${JSON.stringify(error)}`,
        id: 'b6f56abf-4d62-46f8-9030-fde4d062fe73',
      });
      throw error;
    }
  }

  async function exportVendTransSearchToExcel(
    search: VendTransSearchParametersDto,
    protect: boolean,
  ) {
    try {
      const response = await authClient.value?.client.http.post(
        `/vendorapi/ifinanceapi/getvendtransexcel/${protect}`,
        JSON.stringify(search),
        { responseType: 'blob' },
      );
      FileSaver.saveAs(response.data, `VendTrans-${getDateStringForExcel()}.xlsx`);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting vendor transactions excel ${JSON.stringify(error)}`,
        id: 'c40eb5c8-7382-459b-afe3-2679397bad54',
      });
      throw error;
    }
  }

  async function getPODCDocuments(poNumber: string) {
    try {
      const client = await getFinanceClientOrThrow(getFinanceClient.value);
      const response = await callGetPODCDocuments(poNumber, client);
      poDCDocuments.value = response;
      return response;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting PO DC documents ${JSON.stringify(error)}`,
        id: 'b31f7c1a-5b53-4093-821d-5a5ca00a3d4f',
      });
      throw error;
    }
  }

  async function getPODCDocumentFile(poNumber: string, siteId: string, itemId: string) {
    try {
      const response = await authClient.value?.client.http.get(
        `/vendorapi/ifinanceapi/getpodcdocumentcontent/${poNumber}/${siteId}/${itemId}`,
        { responseType: 'blob' },
      );
      FileSaver.saveAs(response.data, `PODCDocument-${poNumber}.pdf`);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `Error getting PO DC document file ${JSON.stringify(error)}`,
        id: 'c9cade67-249a-47cd-9b73-6c0d67f0942c',
      });
      throw error;
    }
  }

  return {
    test,
    salesInvoiceHeaders,
    salesInvoiceHeader,
    salesOrderHeader,
    salesInvoiceLines,
    salesInvoiceSearchResults,
    vendorSearchResult,
    customerSearchResult,
    legalEntityLookup,
    authClient,
    getFinanceClient,
    invoiceData,
    poSearchResults,
    po,
    vendorSearchResultWithRestriction,
    vendTransResult,
    custTransResult,
    custSearchResultWithRestriction,
    vendSettlementResult,
    custSettlementResult,
    poDCDocuments,
    init,
    setTest,
    getLegalEntityLookup,
    searchVendors,
    searchCustomers,
    getSalesInvoiceHeaders,
    getSalesInvoice,
    getInvoiceExcel,
    searchInvoices,
    impersonateD365User,
    getD365UserImpersonation,
    stopImpersonatingD365User,
    isPspFinance,
    getUserData,
    removeCustomerAccount,
    removeVendorAccount,
    addCustomerAccount,
    addVendorAccount,
    deleteUserCacheEntry,
    searchPurchaseOrderHeaders,
    getPOByNumber,
    getPOExcel,
    searchVendorsWithRestriction,
    searchVendTransactions,
    searchCustTransactions,
    addVendorSearchResult,
    searchCustomersWithRestriction,
    addCustomerSearchResult,
    getCustBalance,
    getVendBalance,
    getVendSettlementsForTrans,
    getCustSettlementsForTrans,
    exportCustTransToExcel,
    exportVendTransToExcel,
    exportCustTransSearchToExcel,
    exportVendTransSearchToExcel,
    getPODCDocuments,
    getPODCDocumentFile,
  };
});
