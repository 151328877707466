import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.32.0/./Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withBaseUrl, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { INewItemRequestApi_$reflection, routeBuilder } from "../fable_modules/pogona_vendor_api_lib.fable.1.473.0/INewItemRequestApi.fs.js";
import { toConsole, printf, toText } from "../fable_modules/fable-library-js.4.16.0/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { startAsPromise } from "../fable_modules/fable-library-js.4.16.0/Async.js";
import { singleton } from "../fable_modules/fable-library-js.4.16.0/AsyncBuilder.js";

export function createVendorApiClient(baseUrl, authToken) {
    let options_2;
    return Remoting_buildProxy_64DC51C((options_2 = RemotingModule_withRouteBuilder(routeBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi())), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(authToken), options_2)), INewItemRequestApi_$reflection());
}

export function vendorApiClient(baseUrl, getToken) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr;
        return ((pr = getToken(), pr.then((token) => createVendorApiClient(baseUrl, token))));
    }));
}

export function getNewItemRequestTest(client) {
    return startAsPromise(singleton.Delay(() => singleton.Bind(client.getNewItemRequestTest, (_arg) => {
        const x = _arg;
        toConsole(printf("%s"))(x.ItemDescription);
        return singleton.Return(x);
    })));
}

export function getNewItemRequestById(aggregateId, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.getNewItemRequestById(aggregateId)));
}

export function performNewItemRequestCommands(commands, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.performCommands(commands)));
}

export function uploadImage(command, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.uploadImage(command)));
}

export function deleteImagesNotSpecified(command, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.deleteImagesNotSpecified(command)));
}

export function barcodeMasterInfo(upc, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.barcodeMasterInfo(upc)));
}

export function getHierarchyLookup(client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.getHierarchyLookup));
}

export function getContentHubProduct(upc, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.getContentHubProduct(upc)));
}

export function getContentHubProductRetailMeasure(id, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.getContentHubProductRetailMeasure(id)));
}

export function uploadItemChange(command, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.uploadItemChange(command)));
}

export function downloadAdfUploadFile(command, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.downloadAdfUploadFile(command)));
}

export function processPriceChangeFile(pipeline, filepath, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.processPriceChangeFile(pipeline, filepath)));
}

export function updateProcessFileRunId(processFileId, runId, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.updateProcessFileRunId(processFileId, runId)));
}

export function updateProcessFileStatus(processFileId, status, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.updateProcessFileStatus(processFileId, status)));
}

export function createBatch(batch, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.createBatch(batch)));
}

export function archiveBatch(batch, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.archiveBatch(batch)));
}

export function unArchiveBatch(batch, client) {
    return singleton.Delay(() => singleton.ReturnFrom(client.unArchiveBatch(batch)));
}

export function callGetNewItemTemplates(client) {
    const pr = startAsPromise(client.getNewItemTemplates);
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting new item templates: ${result.fields[0]}`);
        }
        else {
            return result.fields[0];
        }
    });
}

