import Vue from 'vue';
import Router from 'vue-router';
import * as views from './views';

Vue.use(Router);

export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: views.default.Home,
    },
    {
      path: '/logout',
      name: 'logout',
      component: views.default.Logout,
    },
    {
      path: '/sl/labelprint',
      name: 'sl_label_print',
      component: views.default.LabelPrint,
    },
    {
      path: '/sl/ingredientprint',
      name: 'sl_ingredient_print',
      component: views.default.IngredientPrint,
    },
    {
      path: '/sl/ingredientstageprint',
      name: 'sl_ingredient_stage_print',
      component: views.default.IngredientPrintStaging,
    },
    {
      path: '/sl/batchcheck',
      name: 'sl_batch_check',
      component: views.default.BatchCheck,
    },
    {
      path: '/sl/storesetprint',
      name: 'sl_store_set_print',
      component: views.default.StoreSetPrint,
    },
    {
      path: '/sl/storesetcompare',
      name: 'sl_store_set_compare',
      component: views.default.StoreSetCompare,
    },
    {
      path: '/sl/handheldprint',
      name: 'sl_hand_held_print',
      component: views.default.HandHeldPrint,
    },
    {
      path: '/sl/activatedeactivate',
      name: 'sl_activate_deactivate',
      component: views.default.ActivateDeactivate,
    },
    {
      path: '/sl/printeralign',
      name: 'sl_printer_align',
      component: views.default.PrinterAlign,
    },
    {
      path: '/sl/render-progress/:batchInfo',
      name: 'sl_render_progress',
      component: views.default.RenderProgress,
    },
    {
      path: '/sl/unit-override',
      name: 'sl_unit_override',
      component: views.default.UnitOverride,
    },
    {
      path: '/sl/admin/import-data',
      name: 'sl_admin_import_data',
      component: views.default.ImportStoreSet,
    },
    {
      path: '/sl/admin/store-info-bridge',
      name: 'sl_admin_store_info_bridge',
      component: views.default.StoreInfoBridge,
    },
    {
      path: '/sl/admin/new-store-set/:importId',
      name: 'sl_new_import_store_set',
      component: views.default.NewStoreSetPrint,
    },
    {
      path: '/pogona/admin/ad-user-roles',
      name: 'pa_pogona_ad_user_roles',
      component: views.default.AdUserRoles,
    },
    {
      path: '/pogona/admin/store-login-mappings',
      name: 'pa_pogona_store_login_mappings',
      component: views.default.StoreLoginMappings,
    },
    {
      path: '/sl/storesetstageprint',
      name: 'sl_store_set_stage_print',
      component: views.default.StoreSetStagingPrint,
    },
    {
      path: '/sl/renderingsprogress',
      name: 'sl_renderings_progress',
      component: views.default.RenderingsProgress,
    },
    {
      path: '/cm/costandmovement',
      name: 'cm_cost_and_movement',
      component: views.default.CostAndMovement,
    },
    {
      path: '/pogona/admin/neighbor-arrival-notification-enabled-stores',
      name: 'pa_neighbor_arrival_notification_enabled_stores',
      component: views.default.NeighborArrivalNotificationEnabledStores,
    },
    {
      path: '/pogona/admin/usernameactivity',
      name: 'pa_usernameactivity',
      component: views.default.UsernameActivity,
    },
    {
      path: '/sl/admin/deactivations',
      name: 'sl_deactivations',
      component: views.default.Deactivations,
    },
    {
      path: '/sl/admin/allowdeactivationitem',
      name: 'sl_allowdeactivationitem',
      component: views.default.AllowDeactivationItem,
    },
    {
      path: '/ecom/autoshipexceptions/:tab',
      name: 'ecom_autoshipexceptions',
      component: views.default.AutoShipExceptions,
    },
    {
      path: '/ecom/autoshippartnerfunding/:tab',
      name: 'ecom_autoshippartnerfunding',
      component: views.default.AutoShipPartnerFunding,
    },
    {
      path: '/ecom/couponimageupload',
      name: 'ecom_couponimageupload',
      component: views.default.CouponImageUpoad,
    },
    {
      path: '/ecom/ignoreditemsupload',
      name: 'ecom_ignoreditemsupload',
      component: views.default.IgnoreItemsUpload,
    },
    {
      path: '/ecom/exclusionitemsmanager',
      name: 'ecom_exclusionditemsmanager',
      component: views.default.IgnoreItemManager,
    },
    {
      path: '/sl/admin/storesetassignchanges',
      name: 'sl_storesetassignchanges',
      component: views.default.StoreSetAssignChanges,
    },
    {
      path: '/ecom/exportproductxml',
      name: 'ecom_exportproductxml',
      component: views.default.ProductXmlExport,
    },
    {
      path: '/pcl/landing',
      name: 'pcl_landing',
      component: views.default.PclLanding,
    },
    {
      path: '/pcl/store/new',
      name: 'pcl_newstore',
      component: views.default.PclNewStore,
    },
    {
      path: '/pcl/store/:storeId',
      name: 'pcl_editstore',
      component: views.default.PclEditStore,
    },
    {
      path: '/pcl/dr/home',
      name: 'pcl_division_region',
      component: views.default.DivisionRegionHome,
    },
    {
      path: '/pcl/dr/edit/:drId',
      name: 'pcl_division_region_edit',
      component: views.default.DivisionRegionEdit,
    },
    {
      path: '/pcl/all',
      name: 'pcl_listing_all',
      component: views.default.PclStoreListingAll,
    },
    {
      path: '/pcl/owners',
      name: 'pcl_owners',
      component: views.default.PclOwners,
    },
    {
      path: '/merch/precountreports',
      name: 'merch_precountreports',
      component: views.default.PreCountReports,
    },
    {
      path: '/merch/d365discountworksheet',
      name: 'merch_d365discountworksheet',
      component: views.default.D365DiscountWorksheet,
    },
    {
      path: '/vendorportal/newitemrequesthome',
      name: 'vendorportal_newitemrequesthome',
      component: views.default.NewItemRequestHome,
    },
    {
      path: '/vendorportal/batchsearch',
      name: 'vendorportal_batchsearch',
      component: views.default.BatchSearch,
    },
    {
      path: '/vendorportal/itemupdatehome',
      name: 'vendorportal_itemupdatehome',
      component: views.default.ItemUpdateHome,
    },
    {
      path: '/vendorportal/itemchangeupload',
      name: 'vendorportal_itemchangeupload',
      component: views.default.UploadItemUpdateView,
    },
    {
      path: '/vendorportal/itemchangesearch',
      name: 'vendorportal_itemchangesearch',
      component: views.default.ItemUpdateSearch,
    },
    {
      path: '/vendorportal/newitemtemplate',
      name: 'vendorportal_newitemtemplate',
      component: views.default.ManageNewItemTemplate,
    },
    {
      path: '/vendorportal/upcgenerator',
      name: 'vendorportal_upcgenerator',
      component: views.default.UpcGenerator,
    },
    {
      path: '/vendorportal/invoicehome',
      name: 'vendorportal_invoicehome',
      component: views.default.InvoiceHome,
    },
    {
      path: '/vendorportal/invoicedetail/:invoiceNumber',
      name: 'vendorportal_invoicedetail',
      component: views.default.InvoiceDetail,
    },
    {
      path: '/vendorportal/invoicesearchresult/:invoiceSearch/:fromDate/:toDate',
      name: 'vendorportal_invoicesearchresult',
      component: views.default.InvoiceSearchResult,
    },
    {
      path: '/vendorportal/posearchhome/:poSearch/:poName/:siteId/:fromDate/:toDate',
      name: 'vendorportal_posearchhome',
      component: views.default.POSearchHome,
    },
    {
      path: '/vendorportal/vendtranssearchhome/:paymentRef/:vendAcct/:fromDate/:toDate/:transStatus',
      name: 'vendorportal_vendtranssearchhome',
      component: views.default.VendTransSearchHome,
    },
    {
      path: '/vendorportal/custtranssearchhome/:paymentRef/:custAcct/:fromDate/:toDate/:transStatus',
      name: 'vendorportal_custtranssearchhome',
      component: views.default.CustTransSearchHome,
    },
    {
      path: '/vendorportal/vendtranspayrefdetail/:dataAreaId/:voucher',
      name: 'vendorportal_vendtranspayrefdetail',
      component: views.default.VendTransPayRefDetail,
    },
    {
      path: '/vendorportal/custtranspayrefdetail/:dataAreaId/:voucher',
      name: 'vendorportal_custtranspayrefdetail',
      component: views.default.CustTransPayRefDetail,
    },
    {
      path: '/vendorportal/custbalance',
      name: 'vendorportal_custbalance',
      component: views.default.CustBalance,
    },
    {
      path: '/vendorportal/vendbalance',
      name: 'vendorportal_vendbalance',
      component: views.default.VendBalance,
    },
    {
      path: '/vendorportal/podetail/:poNumber',
      name: 'vendorportal_podetail',
      component: views.default.PODetail,
    },
    {
      path: '/vendorportal/managed365impersonation',
      name: 'vendorportal_managed365impersonation',
      component: views.default.ManageD365Impersonation,
    },
    {
      path: '/vendorportal/uservendoraccounts',
      name: 'vendorportal_uservendoraccounts',
      component: views.default.UserVendorAccounts,
    },
    {
      path: 'vendorportal_prebookportal',
      name: 'vendorportal_prebookportal',
      beforeEnter: () => {
        window.open(
          'https://apps.powerapps.com/play/e/default-972c0821-fe2d-4379-b6e8-acd0c3427548/a/d0fa742e-5d45-421b-8446-6961eb169adb?tenantId=972c0821-fe2d-4379-b6e8-acd0c3427548&source=email',
          '_blank',
        );
      },
    },
    {
      path: 'vendorportal_promoportalvendor',
      name: 'vendorportal_promoportalvendor',
      beforeEnter: () => {
        window.open('https://psp.impactanalytics.co/due-date', '_blank');
      },
    },
    {
      path: 'vendorportal_promoportalmerchant',
      name: 'vendorportal_promoportalmerchant',
      beforeEnter: () => {
        window.open('https://psp.impactanalytics.co/submit-offers', '_blank');
      },
    },
    {
      path: 'vendorportal_powerbi',
      name: 'vendorportal_powerbi',
      beforeEnter: () => {
        window.open('https://app.powerbi.com/', '_blank');
      }
    },
    {
      path: '/merch/hotdeals',
      name: 'merch_hotdeals',
      component: views.default.HotDeals,
    },
    {
      path: '/util/keytracking',
      name: 'util_keytracking',
      component: views.default.KeyTracking,
    },
    {
      path: '/',
      redirect: 'home',
    },
    {
      path: '/error*',
      redirect: 'home',
    },
    {
      path: '*',
      component: views.default.NotFound,
    },
  ],
});
